@import "../../global.scss";

.frame {
  padding: 10px;
  display: flex;
}
.frameRed {
  background-color: $bg-rose;
}
.frameYellow {
  background-color: $bg-yellow;
}

.frameRose {
  background-color: $bg-rose;
}

.frameRose2 {
  background-color: $bg-rose2;
}

.frameRose3 {
  background-color: $bg-rose3;
}


.framePink {
  background-color: $bg-pink;
}

.frameLightPink {
  background-color: $bg-pink-light2;
}

