@import "../../global";

h1 {
  font-family: $font-family-handwrite;
  color: $text-color-default2;
  font-size: 50px;
  padding-top: 100px;
  margin: 0;
  background-image: url('../../Flower\ title.png');
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: top;
  text-align: center;
}