@font-face {
  font-family: Artisan;
  src: url("./theartisan.otf") format("opentype");
}

$white: #fff;
$text-color-default: #2c2c2c;
$text-color-default2: #6E1A18;

/*masala colors*/
$bg-brown: #28140a;
$bg-light-brown: #744434;
$bg-yellow: #eed00d;
$bg-orange: #fa7901;
$bg-red: #c63600;

$bg-rose: #581c27;
$bg-rose2: #4c111c;
$bg-rose3: #440b13;
$bg-rose4: #3b0a0f;
$bg-rose5: #270609;
$bg-rose6: #E6A7A0;

$bg-pink: #912f4e;
$bg-pink2: #993152;

$bg-pink-light: #f8f5f2;
$bg-pink-light2: #FBE5D8;


$font-family-default: "Roboto", sans-serif;
$font-family-handwrite: "Artisan", cursive;
$font-family-handwrite2: "Artisan", cursive;
// $font-family-handwrite: "Parisienne", cursive;
// $font-family-handwrite2: "Great Vibes", cursive;



