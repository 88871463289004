@import "../../global";

.bannerParent {
  position: relative;
}
.banner {
  background-size: cover;

  :global(.parallax-banner-layer-0) {
    background-position: 70% 40% !important;
  }
}

.SaveTheDate {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: $font-family-handwrite;
  font-size: 40px;

  @media (max-width: 800px) {
    font-size: 30px;
  }
  font-weight: bold;
  text-align: center;
  color: white;
  text-shadow: -1px -1px 0 #6E1A18, 1px -1px 0 #6E1A18, -1px 1px 0 #6E1A18, 1px 1px 0 #6E1A18;
  p{
    margin: 0;
  }
  .DeyvisonVanessa {
    font-size: 60px;
    @media (max-width: 800px) {
      font-size: 30px;
    }
  }
}
