@import "../../global";

.marriage {
  display: grid;
  width: 100%;
  grid-template-columns: 1fr;
  text-align: center;

  @media (max-width: 800px) {
    & {
      grid-template-columns: 1fr;
    }
  }
  .wherewhen {
    flex: 1;
    display: flex;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-size: 400%;
    font-style: italic;
    color: #6E1A18;
    font-family: $font-family-handwrite2;
    background-color: #faf9f7;
    font-weight: bold;
  }
  .details {
    flex: 1;
    background-color: #faf9f7;
    color: $text-color-default2;
  }
  h2 {
    font-weight: normal;
  }
  h1 {
    font-family: $font-family-handwrite;
    color: $text-color-default2;
    font-size: 50px;
    padding-top: 100px;
    margin: 0;
    background-image: url('../../Flower\ title.png');
    background-size: 300px;
    background-repeat: no-repeat;
    background-position: top;
  }
}
