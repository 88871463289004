.navbar {
  position: fixed;
  z-index: 10;
  width: 100%;

  .flag {
    display: flex;
    justify-items: center;
  }
  .hamburguerMenu {
    display: block;
    padding: 15px 30px;
    text-decoration: none;
    color: #2c2c2c;
    user-select: none;
    background-color: #fff;
    cursor: pointer;
    text-align: left;
    font-size: 27px;
    font-family: "Parisienne", cursive;
    .icon {
      float: right;
    }
  }
  @media (min-width: 800px) {
    right: 10%;
    width: auto;
  }

  ul {
    display: flex;
    flex-direction: column;
    list-style: none;
    white-space: nowrap;
    width: 100%;
    padding: 0;
    margin-top: 0;

    @media (min-width: 800px) {
      flex-direction: row;
    }
    li:first-child {
      @media (min-width: 800px) {
        border-top-left-radius: 40%;
        border-bottom-left-radius: 40%;
      }
    }
    li:last-child {
      @media (min-width: 800px) {
        border-top-right-radius: 40%;
        border-bottom-right-radius: 40%;
      }
    }

    li {
      border: 1px solid rgb(231, 228, 228);
      background-color: #fff;
      width: 100%;

      &:hover {
        cursor: pointer;
        background-color: #e3e3e3;
        color: #666565;
      }
    }
    a {
      display: block;
      padding: 15px 30px;
      text-decoration: none;
      user-select: none;
      color: #2c2c2c;
      width: 100%;
      @media (min-width: 800px) {
      }
      &:hover {
        color: #666565;
      }
    }
  }
}
