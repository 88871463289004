.frame {
  display: flex;
  flex-direction: column;
  p {
    color: #681516;
    font-weight: bold;
    font-size: 25px;
    text-align: center;
  }
}
.carrousel {
  max-width: 600px;
  margin: 0 auto;
  border: 1px solid rgb(231, 228, 228);
}
.images img {
  object-fit: cover;
  width: 100%;
  height: 500px;
}
