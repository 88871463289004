@import "../../global.scss";

.countdown {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $bg-pink-light;
  padding-bottom: 20px;
  h3 {
    font-family: $font-family-handwrite;
    font-size: 40px;
    color: $text-color-default2;
    padding-top: 100px;
    margin: 0;
    background-image: url("../../Flower\ title.png");
    background-position: top;
    background-repeat: no-repeat;
    background-size: 300px;
  }

  .countdownItems {
    display: flex;
    div {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      p {
        display: flex;
        padding: 20px;
        margin: 10px;
        font-size: xx-large;
        border: 4px dotted $text-color-default2;
        color: $text-color-default2;
        border-radius: 50%;
      }
      span {
        font-size: large;
        color: $text-color-default2;
      }
    }
  }

  .letter {
    margin-top: 20px;
    padding: 0;
    font-family: $font-family-handwrite2;
    background-image: url("./Flower\ Crown.png");
    background-size: 410px;
    background-repeat: no-repeat;
    background-position: center 50px;
    width: 440px;
    height: 580px;
    position: relative;
    font-size: 70px;
    color: #6E1A18;

    .letterV {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      font-weight: bold;
      transform: translate(-200%, -70%);
    }
    .letterE {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      transform: translate(-45%, -45%);
      font-size: 70px;
    }
    .letterD {
      position: absolute;
      top: 50%;
      left: 50%;
      margin: 0;
      transform: translate(50%, -0%);
      font-weight: bold;
    }
  }
}
