@import "../../global.scss";
.Wall {
  background-color: $bg-rose6;
  display: flex;
  flex-direction: column;
  align-items: center;
  .Frame {
    width: 100%;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    h1 {
      margin: 0;
      font-family: $font-family-handwrite;
      color: $text-color-default2;
      font-size: 50px;
      padding-top: 100px;
      min-width: 300px;
      text-align: center;
      background-image: url("../../Flower\ title.png");
      background-size: 300px;
      background-repeat: no-repeat;
      background-position: top;
    }
    .WallForm {
      display: flex;
      flex-direction: column;
      background-color: $bg-pink-light2;
      width: 80%;
      .WallInput {
        margin-bottom: 20px;
      }
    }
  }
  .snackbar {
    div {
      background-color: $bg-pink;
    }
  }
}
