html {
  scroll-behavior: smooth;
}

body {
  background-color: #f8f8f8;
  color: #333333;
  font-family: "Roboto", sans-serif;
  overflow-x: hidden;
}
